<template>
    <div class="advancedQuery-container">
        <div class="back-container">
            <div class="main">
                <!-- <img class="img" src="../../assets/images/square/fh.png" alt="" @click="back"> -->
                <!-- <span @click="back" class="txt">返回</span> -->
            </div>
        </div>
        <div class="main">
            <div class="header">
                <span>高级查询</span>
                <span @click="historyQuery">历史查询记录</span>
            </div>
            <div class="body">
                <div class="item" v-show="tags.length !== 0" style="border-bottom: 1px solid #f3f3f3; padding: 10px 0">
                    <label>已选条件</label>
                    <div class="tag" style="width: 800px">
                        <el-tag v-for="(tag, index) in tags" :key="index" closable size="small " @close="handleTagClose(tag)">{{ tag.name }}</el-tag>
                    </div>
                    <div style="position: absolute; right: 0">
                        <el-button icon="el-icon-delete" size="mini" @click="tags = []">清空</el-button>
                        <el-button type="primary" size="mini" @click="result">查看结果</el-button>
                    </div>
                </div>
                <div class="item" style="display: flex; align-items: center">
                    <label>关&nbsp;键&nbsp;词</label>
                    <el-input v-model="keyword" class="el-input" placeholder="请输入关键字">
                    </el-input>
                </div>
                <div class="item">
                    <label>搜索范围</label>
                    <div class="item-right">
                        <span class="touch" v-for="(item, index) in searchData" :key="item.type" @click="addTags(item, 0)">{{ item.name }}</span>
                    </div>
                </div>
                <div class="item">
                    <label>省份地区</label>
                    <div class="item-right">
                        <span class="touch" v-for="(item, index) in proList" :key="item.provinceid" @click="addTags(item, 1)">{{ item.province }}</span>
                    </div>
                </div>
                <div class="item">
                    <label>行业分类</label>
                    <div class="item-right">
                        <span class="touch" v-for="(item, index) in industryData" :key="item.type" @click="addTags(item, 0)">{{ item.name }}</span>
                    </div>
                </div>
                <div class="item">
                    <label>组织类型</label>
                    <div class="item-right">
                        <span class="touch" v-for="(item, index) in organizationData" :key="item.type" @click="addTags(item, 0)">{{ item.name }}</span>
                    </div>
                </div>
                <div class="item">
                    <label>企业类型</label>
                    <div class="item-right">
                        <span class="touch" v-for="(item, index) in enterpriseData" :key="index" @click="addTags(item, 0)">{{ item.name }}</span>
                    </div>
                </div>
                <div class="item">
                    <label>企业状态</label>
                    <div class="item-right">
                        <span class="touch" v-for="(item, index) in state" :key="index" @click="addTags(item, 0)">{{ item.name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="" :visible.sync="dialogVisible1" width="1200px" :before-close="handleDialogClose1">
            <div class="header">
                <img src="../../assets/images/square/ffcx.png" width="1140px; height:290px; " alt="" />
            </div>
            <div class="dialog-title">
                <img src="../../assets/images/square/ts.png" alt="" style="margin-right: 5px; width: 25px; height: 25px" />
                正在【数据广场】-付费查询
            </div>
            <div style="width: 1140px; height: 0px; border: 1px solid #eeeeee"></div>
            <div class="dialog-body">
                <p style="
            margin: 30px 15px 43px 15px;
            font-size: 20px;
            color: #222222;
            font-weight: 400;
          ">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为保证您阅读流畅，【数据广场】的付费项目将绑定你的钱包，在付费前给出支付提示并自动从钱包中扣除，<br />下面将绑定你的钱包：
                </p>
                <div class="pay">
                    <span style="color: #e40012">*</span><label>支付方式</label>
                    <div class="wallet" :style="
              payType
                ? 'border: 1px solid #E40012;'
                : 'border: 1px solid #eaeaea;'
            ">
                        <img v-show="payType" style="width: 16px; height: 16px" src="../../assets/images/square/xz.png" alt="" @click="payType = false" />
                        <div v-show="!payType" class="circle" @click="payType = true"></div>
                        <img style="width: 20.11px; height: 19.45px; margin-left: 28px" src="../../assets/images/square/wdqb.png" alt="" />
                        <span style="color: #222222; margin-left: 11px">我的钱包</span>
                        <span style="font-size: 14px; margin-left: 20px">余额{{ info.balance }}元</span>
                        <span style="margin-left: auto">平台</span>
                    </div>
                    <span style="color: #e40012">*</span><label>支付密码</label>
                    <div class="payPassword">
                        <input ref="payPwd" @input="ipt(index)" @keydown.delete.prevent="iptDel(index)" v-model="password[index]" v-for="(item, index) in password" :key="index" maxlength="1" type="password" class="item" />
                    </div>
                    <span style="color: #00a71c">你的隐私正在保护中...</span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="dialog-btn" @click="bindImmediately">立即绑定</el-button>
            </span>
        </el-dialog>
        <!-- 弹出框 -->
        <el-dialog title="" :visible.sync="dialogVisible" width="1200px" :before-close="handleDialogClose">
            <div class="dialog-title">
                <img src="../../assets/images/square/ts.png" alt="" style="margin-right: 5px; width: 25px; height: 25px" />
                正在<span style="color: #e40012">高级查询</span>
            </div>
            <div style="width: 1140px; height: 0px; border: 1px solid #eeeeee"></div>
            <div class="dialog-body">
                <div style="display: flex">
                    <label v-show="tags.length !== 0">已筛条件</label>
                    <div class="tag" style="width: 1000px">
                        <el-tag v-for="(tag, index) in tags" :key="index" type="info" color="#fff" size="small ">{{ tag.name }}</el-tag>
                    </div>
                </div>
                <div style="margin-top: 30px">
                    <label for="">购买金额</label><span style="color: #e40012">￥{{needMoney}}</span>
                </div>
                <div style="color: #00a71c; margin-top: 70px">注：已跟你确认并绑定你的钱包</div>
                <span style="color:red">已查询过的数据再次查询不会扣减余额!</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="dialog-btn" @click="confrimPay">确认支付</el-button>
                <el-button class="dialog-btn" style="border: 1px solid #e40012; background: #ffffff; color: #e40012" type="primary" @click="dialogVisible = false">取消支付</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import back from "../../components/square/back.vue";
export default {
    name: "advancedQuery",
    components: {
        back,
    },
	metaInfo: {
		title: '企业工商信息查询_查企业_查机构_查联系方式_企胖胖', // set a title
		meta: [{
				name: 'description',
				content: '企胖胖专门服务于中小微企业的专业企业服务平台、涵盖咨询管理、工商账税、知识产权、营销管理等行业、提供最新商业资讯动态、让资讯数据及时动态掌握'
			},
			{
				name: 'keyWords',
				content: '企胖胖，工商账税，知识产权，营销管理，资质动态，商业资讯'
			},
		]
	},
    data() {
        return {
            text: {
                type: String,
                default: '返回'
            },
            dialogVisible: false,
            keyword: "",
            proList: [],
            state: [
                { name: "存续", type: "1", id: 5 },
                { name: "注销", type: "2", id: 5 },
                { name: "吊销", type: "3", id: 5 },
                { name: "撤销", type: "4", id: 5 },
                { name: "迁出", type: "5", id: 5 },
                { name: "设立中", type: "6", id: 5 },
                { name: "清算中", type: "7", id: 5 },
                { name: "停业", type: "8", id: 5 },
                { name: "其他", type: "9", id: 5 },
            ],
            enterpriseData: [
                { name: "国有企业", type: "", id: 4 },
                { name: "集体企业", type: "", id: 4 },
                { name: "股份合作企业", type: "", id: 4 },
                { name: "联营企业", type: "", id: 4 },
                { name: "有限责任公司", type: "", id: 4 },
                { name: "股份有限公司", type: "", id: 4 },
                { name: "私营企业", type: "", id: 4 },
                { name: "港、澳、台商投资企业", type: "", id: 4 },
                { name: "外商投资企业", type: "", id: 4 },
                { name: "个体工商户", type: "", id: 4 },
                { name: "内资企业分支机构", type: "", id: 4 },
                { name: "港、澳、台商投资企业分支机构", type: "", id: 4 },
                { name: "外商投资企业分支机构", type: "", id: 4 },
                { name: "其他企业", type: "", id: 4 },
            ],
            searchData: [
                { name: "企业名称", type: "ename", id: 0 },
                { name: "股东", type: "partner", id: 0 },
                { name: "法定代表人", type: "oper", id: 0 },
                { name: "高管", type: "member", id: 0 },
                { name: "联系方式", type: "contact", id: 0 },
                { name: "经营范围", type: "scope", id: 0 },
                { name: "网址", type: "domain", id: 0 },
                { name: "产品", type: "product", id: 0 },
                { name: "商标", type: "trademark", id: 0 },
                { name: "专利", type: "patent", id: 0 },
                { name: "著作权名称", type: "copyright", id: 0 },
                { name: "软件著作权名称", type: "software", id: 0 },
            ],
            tags: [],
            industryData: [
                { name: "农、林、牧、渔业", type: "A0000", id: 2 },
                { name: "采矿业", type: "B0000", id: 2 },
                { name: "制造业", type: "C0000", id: 2 },
                { name: "电力、热力、燃气及水生产和供应业", type: "D0000", id: 2 },
                { name: "建筑业", type: "E0000", id: 2 },
                { name: "批发和零售业", type: "F0000", id: 2 },
                { name: "交通运输、仓储和邮政业", type: "G0000", id: 2 },
                { name: "住宿和餐饮业", type: "H0000", id: 2 },
                { name: "信息传输、软件和信息技术服务业", type: "I0000", id: 2 },
                { name: "金融业", type: "J0000", id: 2 },
                { name: "房地产业", type: "K0000", id: 2 },
                { name: "租赁和商务服务业", type: "L0000", id: 2 },
                { name: "科学研究和技术服务业", type: "M0000", id: 2 },
                { name: "水利、环境和公共设施管理业", type: "N0000", id: 2 },
                { name: "居民服务、修理和其他服务业", type: "O0000", id: 2 },
                { name: "教育", type: "P0000", id: 2 },
                { name: "卫生和社会工作", type: "Q0000", id: 2 },
                { name: "文化、体育和娱乐业", type: "R0000", id: 2 },
                { name: "公共管理、社会保障和社会组织", type: "S0000", id: 2 },
                { name: "国际组织", type: "T0000", id: 2 },
            ],
            organizationData: [
                { name: "上市公司", type: "listed", id: 3 },
                { name: "新三板", type: "newotc", id: 3 },
                { name: "律所", type: "lawFirm", id: 3 },
                { name: "社会组织", type: "association", id: 3 },
                { name: "香港企业", type: "hongkong", id: 3 },
                { name: "投资机构", type: "investOrg", id: 3 },
            ],
            info: "",
            needMoney: '',
            // keyWord: "",
            dialogVisible1: false,
            payType: false,
            password: ["", "", "", "", "", ""],
            isBindWallet: false,
            info: "",
        };
    },
    methods: {
        //返回
        back() {
            this.$router.push({ path: "/square" })
        },
        getMoney() {
            this.$util.post("/sys-param/list", {
                paramKey: 'BASE*高级搜索'
            }).then((res) => {
                console.log(res);
                this.needMoney = res.data[0].paramValue
            });
        },
        getInfo() {
            this.$util.post("/user/info").then((res) => {
                this.info = res.data;
            });
        },
        addTags(info, type) {
            // console.log(info);
            if (this.tags.length > 0) {
                console.log(this.tags, info)
                let contentIndex = 0
                for (var i in this.tags) {
                    if (this.tags[i].id == info.id) {
                        contentIndex += 1
                    }
                }
                setTimeout(() => {
                    if (contentIndex == 0) {
                        if (info.province) {
                            this.tags.push({ name: info.province, type: info.provinceid, id: info.id });
                        } else {
                            this.tags = [...this.tags, info]
                        }
                    }
                }, 10);
            } else {
                if (type == 0) {
                    this.tags.push(info);
                    // console.log(2);

                } else {
                    this.tags.push({ name: info.province, type: info.provinceid, id: info.id });
                }
            }
        },
        // 获取省
        getPro() {
            this.$util.post("/address/provinces").then((res) => {
                this.proList = res.data;
                for (var i in this.proList) {
                    this.proList[i].id = 1;
                }
                console.log(this.proList);
            });
        },
        historyQuery() {
            this.$router.push({ path: "/historyQuery" });
        },
        handleTagClose(tag) {
            this.tags.splice(this.tags.indexOf(tag), 1);
        },
        result() {
            // console.log('结果')
			console.log(11111)
            if (this.keyword == '') {
                return this.$message.error("请输入搜索关键词");
            } else if (sessionStorage.getItem("pwd") == null) {
                return this.dialogVisible1 = true;
            } else {
                this.dialogVisible = true;
            }

        },
        handleDialogClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => { });
        },
        ipt(index) {
            // 输入后聚焦到下一input
            if (index != 5) this.$refs.payPwd[index + 1].focus();
        },
        iptDel(index) {
            this.password.splice(index, 1, "");
            // 删除后聚焦前一input
            this.$nextTick(() => {
                if (index != 0) this.$refs.payPwd[index - 1].focus();
            });
        },
        confrimPay() {
            this.$router.push({
                path: "/searchResult",
                query: { tags: JSON.stringify(this.tags), keyword: this.keyword },
            });
        },
        handleDialogClose1(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => { });
        },
        bindImmediately() {
            var pwd = "";
            if (this.password.length == 0) {
                return this.$message.error("请输入支付密码");
            }
            for (var i in this.password) {
                pwd += this.password[i];
            }

            this.$util
                .post("user/checkPayPsd", {
                    psd: pwd,
                })
                .then((res) => {
                    console.log(res.code);
                    if (res.code == 200) {
                        sessionStorage.setItem("pwd", pwd);
                        this.isBindWallet = true;
                        this.$message({
                            message: "绑定成功!现在试试搜索关键字查询吧",
                            type: "success",
                        });
                        this.dialogVisible1 = false;
                    } else {
                        return this.$message.error("密码有误");
                    }
                });
            // 绑定钱包设置为true
        },
    },
    mounted() {
        this.getPro();
        this.getInfo();
        this.getMoney()
        console.log(sessionStorage.getItem("pwd"));
		

    },
    created() {
		console.log('测试bug',11111)
	},
};
</script>

<style lang="scss" scoped>
.back-container {
    width: 100%;
    background-color: #515151;
    height: 42px;
    .main {
        display: flex;
        align-items: center;
        width: 1200px;
        height: 100%;
        margin: auto;

        .img {
            width: 16.76px;
            height: 16.76px;
        }
        .txt {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
        }
    }
}
.touch {
    cursor: pointer;
}
.advancedQuery-container {
    // height: 826px;
}
/deep/.el-dialog__body {
    padding: 0 20px;
}
/deep/ .el-tag {
    margin-right: 20px;
    margin-bottom: 10px;
}
.dialog-title {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 400;
    color: #3d3d3d;
    margin-bottom: 10px;
}
.dialog-body {
    font-size: 16px;
    font-weight: 400;
    padding-top: 30px;
    padding-bottom: 64px;
    .pay {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        .wallet {
            box-sizing: border-box;
            width: 458px;
            height: 50px;
            border: 1px solid #e40012;
            display: flex;
            align-items: center;
            padding: 0 23px;
            margin: 30px 0;
        }
        .circle {
            box-sizing: border-box;
            width: 16px;
            height: 16px;
            border: 1px solid #eaeaea;
            border-radius: 8px;
            &:hover {
                border: 1px solid #e40012;
            }
        }
        .payPassword {
            margin: 30px 0 70px 0;
            .item {
                width: 64px;
                height: 64px;
                text-align: center;
                font-size: 50px;
                background: #ffffff;
                border: 1px solid #d1d1d1;
                margin-right: 30px;
                // outline-color: #E40012;
            }
        }
    }
    label {
        margin-right: 20px;
    }
}
.dialog-btn {
    width: 247px;
    height: 60px;
    background: #e40012;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
}
.dialog-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 293px;
    margin-bottom: 100px;
}
.main {
    width: 1200px;
    margin: 30px auto;
    .header {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        height: 60px;
        background: #d1d1d1;
        // opacity: 0.5;
        padding-left: 20px;
        padding-right: 37px;
        span:first-child {
            font-size: 20px;
            font-weight: 400;
            color: #222222;
            // opacity: 1;
        }
        span:last-child {
            font-size: 16px;
            font-weight: 400;
            // opacity: 1;
            color: #222222;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .body {
        box-sizing: border-box;
        padding: 0 18px;
        padding-bottom: 18px;
        width: 1200px;
        // height: 525px;
        margin-top: 30px;
        // background-color: skyblue;
        box-shadow: 0px 5px 0px 3px #f5f5f5;
        border: 1px solid transparent;

        .item {
            position: relative;
            display: flex;
            margin-top: 20px;

            .item-right {
                width: 888px;
            }

            .tag {
                span {
                    margin-bottom: 10px;
                }
            }
        }
        .item:first-child {
            margin-top: 0;
        }
        label {
            font-size: 15px;
            color: #b0b8c1;
            margin-right: 50px;
        }
        .el-input {
            width: 510px;
            height: 40px;
            // border: 1px solid #dedede;
        }

        span {
            margin-right: 20px;
            font-size: 15px;
            color: #000000;
        }
    }
}
</style>
